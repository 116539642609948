<template>
  <v-data-table
    item-key="id"
    :headers="headers.filter((h) => !dense || !h.denseHidden)"
    :footer-props="footerProps"
    :items="listings"
    :loading="loading"
    :server-items-length="listingsCount"
    :options.sync="options"
    must-sort
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #top>
      <TextField
        autofocus
        prepend-inner-icon="$search"
        :placeholder="searchPlaceholder"
        hide-details
        v-model="searchText"
        clearable
      />
    </template>
    <template #[`item.displayName`]="{ item }">
      <router-link
        :to="{ name: 'Listing', params: { id: item.id } }"
        :target="target"
        >{{ item.displayName }}</router-link
      >
    </template>
    <template #[`item.startDate`]="{ value }">{{ value | date }}</template>
    <template #[`item.endDate`]="{ item, value }">
      <span :title="item.endDateLabel">{{ value | date }}</span>
    </template>
    <template #[`item.extendedEndDate`]="{ value }">{{
      value | date
    }}</template>
    <template #[`item.modifiedOn`]="{ value }">{{ value | date }}</template>
    <template #[`item.publishOn`]="{ value }">{{ value | date }}</template>
    <template #[`item.retirementAnnouncementDate`]="{ value }">{{
      value | date
    }}</template>
    <template #[`item.autoPublish`]="{ value }">{{ value | bool }}</template>
    <template #[`item.approved`]="{ value }">{{ value | bool }}</template>
    <template #[`item.reviewed`]="{ value }">
      <v-icon
        v-if="!value"
        small
        color="warning"
        title="Listing has source data changes that need to be reviewed."
        >$warning</v-icon
      >
    </template>
    <template #[`item.actionItem`]="{ item }">
      <v-icon
        v-if="item.actionItems.length"
        small
        color="blue"
        :title="getActionItemsTooltip(item.actionItems)"
        >$flag</v-icon
      >
    </template>
    <template #[`item.environments`]="{ item }">
      <ListingEnvironmentStatuses class="text-no-wrap" :listing="item" />
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        v-if="!item.isRemoved"
        icon
        title="Archive"
        @click="archiveListing(item)"
      >
        <v-icon small>$archive</v-icon>
      </v-btn>
      <v-btn v-else icon title="Restore" @click="restoreListing(item)">
        <v-icon small>$restore</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import ListingEnvironmentStatuses from './ListingEnvironmentStatuses.vue'
import TableMixin from '@/mixins/TableMixin'
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('Listings')

export default {
  mixins: [TableMixin],
  props: {
    target: String,
    dense: Boolean,
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  components: {
    ListingEnvironmentStatuses
  },
  watch: {
    '$route.query.filter': {
      handler(newValue, oldValue) {
        this.options.page = 1
        if (newValue === 'Ready For Live' || oldValue === 'Ready For Live') {
          this.load()
        }
      }
    }
  },
  data() {
    return {
      options: {
        sortBy: ['displayName'],
        sortDesc: [false],
        itemsPerPage: this.itemsPerPage
      }
    }
  },
  computed: {
    ...mapGetters(['listings', 'listingsCount', 'loading']),
    headers() {
      const headers = []
      const filter = this.$route.query.filter

      headers.push(
        {
          text: 'Product listing name',
          value: 'displayName',
          searchable: true
        },
        {
          text: 'Policy',
          value: 'policy',
          searchable: true
        },
        {
          text: 'Start date',
          value: 'startDate'
        },
        {
          text: 'Mainstream / Retirement',
          value: 'endDate'
        },
        {
          text: 'Extended End',
          value: 'extendedEndDate'
        }
      )

      if (['Upcoming Publish'].includes(filter)) {
        headers.push({
          text: 'Retirement Announcement Date',
          value: 'retirementAnnouncementDate'
        })
      }

      headers.push({
        text: 'Modified on',
        value: 'modifiedOn',
        denseHidden: true
      })

      if (['Ready For Live', 'Upcoming Publish'].includes(filter)) {
        headers.push(
          {
            text: 'Publish date',
            value: 'publishOn'
          },
          {
            text: 'Approved',
            value: 'approved'
          },
          {
            text: 'Auto Publish',
            value: 'autoPublish'
          }
        )
      }

      headers.push(
        {
          text: '',
          value: 'reviewed'
        },
        {
          text: '',
          value: 'actionItem'
        }
      )

      if (filter !== 'Archived') {
        headers.push({
          text: 'Environments',
          value: 'environments',
          sortable: false,
          width: 1
        })
      }

      headers.push({
        value: 'actions',
        sortable: false,
        width: 1,
        denseHidden: true
      })

      return headers
    }
  },
  methods: {
    ...mapActions(['getListings']),
    load: debounce(function () {
      const params = this.oDataQuery
      this.getListings(params)
    }, 200),
    getActionItemsTooltip(items) {
      const truncated = items.map((item) =>
        this.$formatter.truncate(item, { length: 100 })
      )

      return truncated.join('\n----------\n')
    },
    async archiveListing(listing) {
      const message = `Are you sure you want to archive the listing '${listing.displayName}'?`
      if (
        await this.$confirm(message, {
          title: 'Archive Listing?',
          buttonTrueText: 'Archive',
          buttonFalseText: 'Cancel'
        })
      ) {
        await this.$store.dispatch('Listings/archiveListing', listing)
        await this.$store.dispatch(
          'Listings/getFilterCounts',
          this.$route.query.parentId
        )
        await this.load()
      }
    },
    async restoreListing(listing) {
      const message = `Are you sure you want to restore the listing '${listing.displayName}'?`
      if (
        await this.$confirm(message, {
          title: 'Restore Listing?',
          buttonTrueText: 'Restore',
          buttonFalseText: 'Cancel'
        })
      ) {
        await this.$store.dispatch('Listings/restoreListing', listing)
        await this.$store.dispatch(
          'Listings/getFilterCounts',
          this.$route.query.parentId
        )
        await this.load()
      }
    }
  }
}
</script>
