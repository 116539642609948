


















import Vue from 'vue'
import ListingEnvironmentStatus from './ListingEnvironmentStatus.vue'
import IListingFlat from '../models/IListingFlat'

export default Vue.extend({
  components: {
    ListingEnvironmentStatus
  },
  props: {
    listing: Object as () => IListingFlat
  }
})
